<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Postbacks
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								dw-date-time-picker(v-model="filters.datePicker")
								//.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
								.form-group
									label Network
									select.form-control(v-model="filters.network_id", style="width: 120px;")
										option(:value="null") - All -
										option(v-for="n in networkOptions", :value="n.v") {{ n.t }}
								.form-group
									label Status
									select.form-control(v-model="filters.status")
										option(v-for="o in statusOptions", :value="o.v") {{ o.v }} - {{ o.t }}
								.form-group
									label Type
									select.form-control(v-model="filters.type")
										option(v-for="o in typeOptions", :value="o.v") {{ o.t }}
								.form-group
									label Format
									select.form-control(v-model="filters.format")
										option(v-for="o in formatOptions", :value="o.v") {{ o.t }}
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go

				p.clearfix &nbsp;

				paginate(:paginator="paginate", @update-page-size="loadData")

				.table-wrapper
					table.table.table-bordered
						thead
							tr
								th ID
								th Network
								th Status
								th Request
								th Type
								th Format
								th ClickID
								th EventID
								th Query Params
								th Error String
								th Created At
								th Updated At
								th Actions
						tbody
							tr(v-if="records.length===0")
								td(colspan="13") No matching records were found
							tr(v-for="r in records", :key="r.id")
								td {{ r.id }}
								td
									entity(v-if="r.network", :id="r.network.id", :name="r.network.name", type="network")
								td {{ r.status }}
								td
									span.req-preview {{ r.request_string }}
								td {{ r.type }}
								td {{ r.format }}
								td {{ r.click_id }}
									span.text-muted(v-if="r.click_time") <br />{{ r.click_time }}
								td {{ r.event_id }}
								td
									pre.toggleable {{ r.query_params }}
								td {{ r.error_string }}
								td {{ r.created_at }}
								td {{ r.updated_at }}
								td.actions
									//router-link.btn.btn-action.btn-secondary(:to="{name:'postback-log-view', params: {id:r.id}}")
										i.la.la-pen
									//.btn.btn-action.btn-outline-danger()
										i.la.la-trash

				paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
pre {
	background: #f0f0f0;
	padding: 5px;
	border-radius: 3px;
	font-size: 8px;
}
.req-preview {
	font-size: 10px;
	display: inline-block;
	width: 200px;
	word-break: break-all;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import Vue from "vue";
export default {
	name: 'PostbackLog',
	async beforeRouteEnter(to, from, next){
		let networkOptions = await Vue.$dwData.network.getOptions(true);
		next(async (vm) => {
			vm.networkOptions = networkOptions;
			vm.loadData();
			return vm;
		});
	},
	data(){
		return {
			busy: false,
			// PERMS: null,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				keyword: '',
				type: '',
				format: '',
				status: undefined,
				network_id: null,
				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: null,
						endDate: null
					},
				}
			},
			networkOptions: [],
			formatOptions: [
				{v: '', t: 'All'},
				{v: 'appsflyer', t: 'appsflyer'},
			],
			typeOptions: [
				{v: '', t: 'All'},
				{v: 'install', t: 'install'},
				{v: 'event', t: 'event'},
				{v: 'rejection', t: 'rejection'},
				// {v: 'appsflyer:install', t: 'Appsflyer Install'},
				// {v: 'appsflyer:event', t: 'Appsflyer Event'},
				// {v: 'appsflyer:rejection', t: 'Appsflyer Reject'},
				// {v: 'appsflyer:install:privacy', t: 'Appsflyer Install Privacy'},
				// {v: 'appsflyer:event:privacy', t: 'Appsflyer Event Privacy'},
				// {v: 'appsflyer:rejection:privacy', t: 'Appsflyer Reject Privacy'},
				// {v: 3, t: 'Ignore'},
				// {v: 4, t: 'Error'},
				// {v: 5, t: 'Privacy'},
			],
			statusOptions: [
				{v: undefined, t: 'All'},
				{v: 0, t: 'New'},
				{v: 1, t: 'Success'},
				{v: 2, t: 'Retry'},
				{v: 3, t: 'Ignore'},
				{v: 4, t: 'Error'},
				{v: 5, t: 'Privacy'},
			],
			records: [],
			filtersExpanded: false,
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Media' },
			{ title: 'Reports' },
			{ title: 'Postback Log' }
		]);
	},
	methods: {

		// async fetchApi(r){
		// 	if (r._busy_fetch_api){
		// 		return;
		// 	}
		// 	let data = {
		// 		id: r.id
		// 	};
		// 	r._busy_fetch_api = true;
		// 	try {
		// 		await this.$api.post('postback-log/fetchAPI', data);
		// 		r.api_processing = true;
		// 		// r.last_fetch_api = resp.entity.last_fetch_api;
		// 		// r.api_num_offers = resp.entity.api_num_offers;
		// 		// this.$ovNotify.success('Got ' + resp.entity.api_num_offers + ' offers');
		//
		// 	} catch (e) {
		// 		console.error(e);
		// 	}
		// 	r._busy_fetch_api = false;
		// },

		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				type: this.filters.type,
				format: this.filters.format,
				status: this.filters.status,
				network_id: this.filters.network_id,
				date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
			};
			try {
				let resp = await this.$api.get('/postback-log/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	}

}
</script>
